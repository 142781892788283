<template>
  <div class="inspectPlan">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="标样核查-任务计划" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 名称 -->
        <ml-input
          prop="planNameLike"
          placeholder="请输入计划名称"
          style="margin-bottom: 0"
          label="名称:"
          v-model="searchData.planNameLike"
        />
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择单位"
          :options="unitOptions"
          label="单位:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0 }"
          @change="searchFn"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 添加巡检计划 -->
        <!-- <ml-button
          :style="{ 'margin-top': '10px' }"
          :showSubmit="false"
          cancelText="添加巡检计划"
          cancelIcon="el-icon-plus"
          cancelType="primary"
          @click-cancel="clickPush"
        /> -->
      </ml-form>
      <div class="add-btn-box">
        <el-button icon="el-icon-plus" type="primary" @click="clickPush">
          新增标样核查计划
        </el-button>
      </div>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(360)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="单位"
          prop="companyName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="标样核查计划名称"
          prop="planName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="业务类型"
          prop="qualityControlType"
          min-width="15%"
        >
          <template #default="scope">
            <span>{{ scope.row.qualityControlType }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="标样核查类型"
          prop="planType"
          min-width="15%"
        />
        <el-table-column align="center" label="创建时间" prop="createTime" min-width="15%" />
        <el-table-column align="center" label="操作" priop="planId" min-width="25%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickView(scope.$index, scope.row)"
            >
              查看详情
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              v-if="isSuperAdmin"
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 删除巡检计划提示框 -->
    <ml-dialog
      ref="deleteInspectPlanDialogRef"
      :content="deleteInspectPlanData.content"
      @click-submit="submitDeleteInspectPlan"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  getTreeString,
  getProjectsString,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'InspectPlan',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      planNameLike: getMemoryPage.value.searchData.planNameLike || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      planTypeLike: getMemoryPage.value.searchData.planTypeLike || '',
      planBusinessType: '1',
      qualityControlType: '标样核查'
    })

    // 是否是超级管理员
    const isSuperAdmin = computed(() => getters.getIsSuperAdmin)

    // 单位配置
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    // 巡检类型
    const inspectionTypeData = ref([
      { label: '临时巡检计划' },
      { label: '日巡检计划' },
      { label: '周巡检计划' },
      { label: '季度巡检计划' },
      { label: '月巡检计划' },
      { label: '年巡检计划' }
    ])
    // 计划业务类型
    const planBusinessData = ref([
      { value: '0', label: '巡检' },
      { value: '1', label: '质控' }
    ])
    // 质检类型
    const qualityTypeData = ref([
      { label: '标样核查' },
      { label: '校正' },
      { label: '多点线性核查' },
      { label: '水样对比' }
    ])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          getProjectData(newvalue)
        }
      }
    )
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getInspectPlans() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getInspectPlans() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getInspectPlans = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.planNameLike = replacePerCent(searchData.planNameLike)
      searchDataParams.planBusinessType = '1'
      searchDataParams.qualityControlType = '标样核查'
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetInspectPlans',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getInspectPlans()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 添加巡检计划
    const clickPush = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/byTaskPlanAction' })
    }
    // 修改巡检计划
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/byTaskPlanAction', query: { updateId: row.planId } })
    }
    // 查看巡检计划
    const clickView = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/byTaskPlanAction', query: { viewId: row.planId } })
    }
    // 删除巡检计划
    const deleteInspectPlanDialogRef = ref()
    const deleteInspectPlanData = reactive({
      content: '确定删除该标样核查计划？'
    })
    const clickDelete = (index, row) => {
      deleteInspectPlanData.planId = row.planId
      deleteInspectPlanDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteInspectPlan = () => {
      const { planId } = deleteInspectPlanData
      dispatch('fetchRemoveInspectPlan', planId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getInspectPlans() : (tabberData.page = 1)
          deleteInspectPlanDialogRef.value.showDialog = false
        }
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []

      getInspectPlans()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickPush,
      clickUpdate,
      clickDelete,
      deleteInspectPlanData,
      deleteInspectPlanDialogRef,
      submitDeleteInspectPlan,
      unitOptions,
      getTreeString,
      getProjectsString,
      selectUnit,
      projectData,
      inspectionTypeData,
      clickView,
      isSuperAdmin,
      planBusinessData,
      qualityTypeData
    }
  }
}
</script>

<style lang="scss" scoped>
.inspectPlan {
  @extend %params-global;
  .el-input__inner {
    width: 170px;
  }
  .add-btn-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>
